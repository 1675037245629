import { useContext } from "react";
import DataContext from "./DataContext";

const Loading = () => {
    const data = useContext(DataContext);
    return (
        <div className="bouncing-ball-container">
            {data.showLoading && <div className="bouncing-ball"></div>}
        </div>
    );
};

export default Loading;

