import { useState, useEffect } from 'react';
import './App.css';
import GPTwitter from './GPTwitter';
import DataContext from './DataContext';
import { Content, Data, Message } from './myTypes';
import Loading from './Loading';

const initialLocalStorageData = localStorage.getItem('gptwitterData');

const initialData: Data = {
  stack: [],
  roots: [],
  content: {},
  relationships: {},
  level: 0,
  autoIncrementingId: 0,
  showLoading: false
};

function App() {
  const [data, setData] = useState(initialLocalStorageData ? JSON.parse(initialLocalStorageData) : initialData);

  useEffect(() => {
    localStorage.setItem('gptwitterData', JSON.stringify(data));
  }, [data]);

  function convertNumberToId(num: number): string {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');

    if (num < alphabet.length) {
      return alphabet[num];
    } else {
      return (
        convertNumberToId(Math.floor(num / alphabet.length) - 1)
        +
        convertNumberToId(num % alphabet.length)
      );
    }
  }

  const pushOntoStack = (id: string) => {
    setData((prevData: Data) => ({
      ...prevData,
      stack: [...prevData.stack, id]
    }));
  };

  const popFromStack = () => {
    const poppedElement = data.stack[data.stack.length - 1];
    setData((prevData: Data) => ({
      ...prevData,
      stack: prevData.stack.slice(0, -1)
    }));
    return poppedElement;
  };

  const replaceTopOfStack = (id: string) => {
    setData((prevData: Data) => ({
      ...prevData,
      stack: [...prevData.stack.slice(0, -1), id]
    }));
  };

  const replaceStack = (newStack: string[]) => {
    setData((prevData: Data) => ({
      ...prevData,
      stack: newStack
    }));
  }

  const incrementLevel = () => {
    setData((prevData: Data) => ({
      ...prevData,
      level: prevData.level + 1
    }));
  };

  const decrementLevel = () => {
    setData((prevData: Data) => ({
      ...prevData,
      level: prevData.level - 1
    }));
  };

  const addedTweetToRelationship = (parent: string, child: string) => {
    return [...data.relationships[parent], child];
  }

  const addRoot = (content: string) => {
    const rootId = convertNumberToId(data.autoIncrementingId + 1);
    setData((prevData: Data) => ({
      ...prevData,
      roots: [rootId, ...prevData.roots],
      content: { ...prevData.content, [rootId]: { role: "user", content } },
      relationships: { ...prevData.relationships, [rootId]: [] },
      autoIncrementingId: prevData.autoIncrementingId + 1
    }))

    return rootId;
  }

  const addTweet = (rootId: string, content: string) => {
    setData((prevData: Data) => {
      const childId = convertNumberToId(data.autoIncrementingId + 1);
      return {
        ...prevData,
        content: { ...prevData.content, [childId]: { role: "user", content } },
        relationships: { ...prevData.relationships, [rootId]: addedTweetToRelationship(rootId, childId), [childId]: [] },
        autoIncrementingId: prevData.autoIncrementingId + 1
      }
    });
  }

  const addMultipleTweets = (rootId: string, content: string[]) => {
    setData((prevData: Data) => {
      let increment = 1;
      let newRelationships = {};
      const newRelationshipsFromRoot = [...prevData.relationships[rootId]];
      const newContent = { ...prevData.content };

      content.forEach((tweet) => {
        const childId = convertNumberToId(prevData.autoIncrementingId + increment);
        newContent[childId] = { role: "assistant", content: tweet };
        newRelationshipsFromRoot.push(childId);
        newRelationships = { ...newRelationships, [childId]: [] };
        increment++;
      });

      return {
        ...prevData,
        content: newContent,
        relationships: { ...prevData.relationships, [rootId]: newRelationshipsFromRoot, ...newRelationships },
        autoIncrementingId: prevData.autoIncrementingId + increment
      }
    });
  }

  const constructMessages = (stopId: string, lastMessages: Content[], stackAugmentation: string[] = []) => {
    let messages: Message[] = [];
    const stack = [...data.stack, ...stackAugmentation];

    // get all the messages leading back to the root
    for (let i = 0; i < stack.length; i++) {
      const id = stack[i];
      const messageBuffer = [data.content[id]];

      if (i < stack.length - 1) {
        const nextId = stack[i + 1];
        for (const child of data.relationships[id]) {
          if (child === nextId) {
            break;
          }
          messageBuffer.push(data.content[child]);
        }
      }
      messages = [...messages, ...messageBuffer];

      if (id === stopId) {
        break;
      }
    }

    lastMessages.forEach((message) => {
      messages.push(message);
    })

    return messages;
  }

  const forceConstructMessages = (stack: string[]) => {
    let messages: Message[] = [];

    for (let i = 0; i < stack.length; i++) {
      const id = stack[i];
      const messageBuffer = [data.content[id]];

      if (i < stack.length - 1) {
        const nextId = stack[i + 1];
        for (const child of data.relationships[id]) {
          if (child === nextId) {
            break;
          }
          messageBuffer.push(data.content[child]);
        }
      }
      messages = [...messages, ...messageBuffer];
    }


    return messages;
  }

  const _hasChildren = (id: string) => {
    return data.relationships[id].length !== 0;
  }

  const setLoading = (flag: boolean) => {
    setData((prevData: Data) => ({
      ...prevData,
      showLoading: flag
    }));
  }

  return (
    <>
      <DataContext.Provider value={{ ...data, pushOntoStack, popFromStack, replaceTopOfStack, incrementLevel, decrementLevel, addRoot, addTweet, addMultipleTweets, replaceStack, constructMessages, forceConstructMessages, _hasChildren, setLoading }}>

        <Loading />
        <GPTwitter />
      </DataContext.Provider>
    </>
  );
}

export default App;
