import DataContext from './DataContext';
import './App.css'

import Thread from './Thread';
import { useContext } from 'react';
import MainThread from './MainThread';
import { Toaster } from 'sonner';

function GPTwitter() {
    const data = useContext(DataContext);

    return (
        <div className="twitter">
            <MainThread />
            {data.stack.map((id, level) => <Thread key={id} root={id} level={level} />)}
            <Toaster />
        </div>
    )
}

export default GPTwitter;
