import { toast } from 'sonner';
import { Content, DataContextType, SystemCommand } from './myTypes';

const LAPIN_API = "https://server.alexhwjeon.workers.dev/"
// const LAPIN_API = "http://localhost:8787/";

// function findToReplaceWithImageText(input: string): string[] {
//     const regex = /<toReplaceWithImage>(.*?)<\/toReplaceWithImage>/g;
//     const matches = input.match(regex);
//     if (!matches) {
//         return [];
//     }

//     return [matches[0].replace(/<\/?toReplaceWithImage>/g, '')];
// }

// function constructSearchURL(keywords: string[]): string {
//     return `${LAPIN_API}?keywords=${keywords.join(" ")}`;
// }


function isBadReponse(response: Response) {
    switch (response.status) {
        case 400:
        case 401:
        case 403:
        case 404:
            toast.error("Lapin is experiencing some difficulties. Please try again in a couple minutes!");
            return true;
        case 429:
            toast.error("Try again in a couple seconds. The rate limit has been exceeded.");
            return true;
        case 500:
            toast.error("Lapin is experiencing some difficulties. Please try again in a couple minutes.");
            return true;
        case 529:
            toast.error("Anthropic's servers are currently overloaded. Please try again later.");
            return true;
        default:
            return false;
    }
}



export async function getResponse(root: string, messages: Content[], data: DataContextType, cleanup: () => void, additionalPrompt: SystemCommand) {
    data.setLoading(true);

    let messagesToSend = messages;

    if (messages.length > 50) {
        const newMessagesToSend = messagesToSend.slice(-Math.min(messagesToSend.length, 50));
        const lastUserMessage = newMessagesToSend.findIndex((m) => m.role === "user");
        if (lastUserMessage !== -1) {
            messagesToSend = newMessagesToSend.slice(lastUserMessage);
        } else {
            messagesToSend = [
                { role: "user", content: "I'm interested in listening to what you have to say." },
                ...newMessagesToSend
            ];
        }
    }

    const payload = {
        gptCommand: additionalPrompt,
        messages: messagesToSend,
    }

    const textDecoder = new TextDecoder();
    const response = await fetch(LAPIN_API, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://lapin.hajeon.xyz',
        },
        body: JSON.stringify(payload),
    })

    if (isBadReponse(response) || !response.body) {
        cleanup();
        data.setLoading(false);
        return;
    }

    let buffer = '';
    const reader = response.body.getReader();

    try {
        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                break;
            }
            const chunk = textDecoder.decode(value);
            buffer += chunk;
            if (buffer.includes("%BREAK%") && (!buffer.startsWith("%BREAK%"))) {
                const splitBuffer = buffer
                    .split('%BREAK%')
                    .filter((s) => s.length > 0)
                    .map((s) => s.trim());

                const responses = splitBuffer.length > 1 ? splitBuffer.slice(0, -1) : splitBuffer;
                const bufferAdjust = splitBuffer.length > 1 ? splitBuffer[splitBuffer.length - 1] : '';

                // // This code is for images. It works, but it's not working very well.

                // const imageTexts = findToReplaceWithImageText(buffer);
                // if (imageTexts.length > 0) {
                //     const imageRequest = constructSearchURL(imageTexts);
                //     console.log(imageRequest);
                //     const imageResponse = await fetch(imageRequest, {
                //         method: 'GET',
                //         headers: {
                //             'Access-Control-Allow-Origin': '*',
                //         }
                //     });
                //     const imageResponseText = await imageResponse.text();
                //     if (imageResponseText !== "https:") {
                //         console.log(imageResponseText);
                //         responses[responses.length - 1] = responses[responses.length - 1] + `<div classname="tweet-content"><img style="display: block;" width="300px" src="${imageResponseText}" alt="Image from Lapin" /></div>`;
                //     }
                // }

                buffer = bufferAdjust;
                data.addMultipleTweets(root, responses);
            }
        }

        const leftoverBuffer = buffer.split('%BREAK%').filter((s) => s.length > 0).map((s) => s.trim());

        data.addMultipleTweets(root, leftoverBuffer);

    } catch (error) {
        toast.error(`Lapin encountered an unexpected error: ${error}`)
    }
    finally {
        cleanup();
        data.setLoading(false);
    }
}
