import { useContext, useRef, useEffect } from "react";
import DataContext from "./DataContext";

import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import SendButton from "./SendButton";
import { toast } from "sonner";

const DBUG = false;

interface Props {
    flipToDirty: (rootId: string) => void;
    waiting: string | null;
}

function StartThread(props: Props) {
    const data = useContext(DataContext);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const textBoxRef = useRef<HTMLDivElement>(null);

    const editor = useEditor({
        extensions: [StarterKit],
        content: ``,
        onFocus: (evt) => {
            if (!evt.editor.isFocused) return;
            if (textBoxRef.current?.style.getPropertyValue("height") !== "100%") {
                textBoxRef.current?.style.setProperty("height", "100%");
            }
        },
    })

    useEffect(() => {
        if (editor?.isFocused) {
            textBoxRef.current?.style.setProperty("height", "100%");
        }
    }, [editor?.isFocused])

    const focusEditor = () => {
        if (!editor) return;
        if ((editor.isFocused) || (editor.isCapturingTransaction)) return;
        editor.commands.focus();
    }

    const onButtonSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit();
    }

    const onSubmit = () => {
        if (!editor) return;
        const textContent = editor.getText();
        const textContentLength = textContent.trim().length;
        if (textContentLength === 0) return;
        if (textContentLength > 1024) {
            toast.warning("Your request is too long - please keep it under 1024 characters.")
            return;
        }
        const content = editor.getHTML();

        // initiate response
        if (!DBUG) {
            const rootId = data.addRoot(content);
            props.flipToDirty(rootId);
            data.replaceStack([rootId]);
        }

        // clear the input
        editor.commands.clearContent();

        // unfocus editor to move to the next pane
        editor.commands.blur();

        if (buttonRef.current) {
            buttonRef.current.blur();
        }
    }

    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            onSubmit();
        }
    }

    return (
        <div onClick={focusEditor} onKeyDown={onEnter} className="editableTweet">
            <div ref={textBoxRef} className="text-box">
                <EditorContent editor={editor} />
            </div>
            <div className="sendBar">
                <button ref={buttonRef} onClick={onButtonSubmit} style={{ width: "90px" }}>
                    <SendButton />
                </button>
            </div>
        </div>
    )
}

export default StartThread;