import { marked } from "marked";
import DOMPurify from "dompurify";
import { useContext, useState, useEffect, createRef } from "react";

import { Content, DataContextType, SystemCommand } from "./myTypes";
import { getResponse } from './chatStream';

import Tweet from "./Tweet";
import DataContext from "./DataContext";
import EditableTweet from "./EditableTweet";

interface ThreadProps {
    root: string;
    level: number;
}

function Thread(props: ThreadProps) {
    const [gptCommand, setGptCommand] = useState<SystemCommand | null>(null);

    const data: DataContextType = useContext(DataContext);
    const rootId = props.root;
    const root = data.content[rootId].content;

    const childrenIds: string[] = data.relationships[rootId];
    const children: Content[] = childrenIds.map((id) => data.content[id]);

    const threadRef = createRef<HTMLDivElement>();

    const flipToDirty = () => {
        setGptCommand("default");
    }

    useEffect(() => {
        if (threadRef.current) {
            // threadRef.current.scrollTop = threadRef.current.scrollHeight;
            if (data.stack[data.stack.length - 1] === rootId) {
                threadRef.current.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [gptCommand]);

    useEffect(() => {
        if (!gptCommand) return;

        const userMessages = data.constructMessages(rootId, children);

        getResponse(
            rootId,
            userMessages,
            data,
            () => setGptCommand(null),
            gptCommand
        );
    }, [gptCommand])

    const resetStack = (id: string, baseId: string) => {
        let buffer = data.stack;
        while (buffer.length > 0 && buffer[buffer.length - 1] !== baseId) {
            buffer = buffer.slice(0, -1);
        }

        buffer.push(id);

        return buffer;
    }

    const onExpand = (id: string) => {
        return () => {
            const buffer = resetStack(id, rootId);
            const userMessages = data.forceConstructMessages(buffer);

            getResponse(
                id,
                userMessages,
                data,
                () => { },
                "expand"
            );

            data.replaceStack(buffer);
        }
    }

    const onSummarize = (id: string) => {
        return () => {
            const buffer = resetStack(id, rootId);
            const userMessages = data.forceConstructMessages(buffer);

            getResponse(
                id,
                userMessages,
                data,
                () => { },
                "summarize"
            );

            data.replaceStack(buffer);
        }
    }

    const onExample = (id: string) => {
        return () => {
            const buffer = resetStack(id, rootId);
            const userMessages = data.forceConstructMessages(buffer);

            getResponse(
                id,
                userMessages,
                data,
                () => { },
                "example"
            );

            data.replaceStack(buffer);
        }
    }

    const onReply = (id: string) => {
        return () => {
            const buffer = resetStack(id, rootId);
            data.replaceStack(buffer);
        }
    }


    return (
        <div ref={threadRef} className="thread">
            <div className="thread-header" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked.parse(root) as string) }}></div>
            {children.map((c, index) => (
                <Tweet
                    key={index}
                    id={childrenIds[index]}
                    content={c}
                    numChildren={data.relationships[childrenIds[index]].length}
                    onReply={onReply(childrenIds[index])}
                    onExpand={onExpand(childrenIds[index])}
                    onSummarize={onSummarize(childrenIds[index])}
                    onExample={onExample(childrenIds[index])}
                />
            ))}
            <EditableTweet root={rootId} flipToDirty={flipToDirty} waiting={gptCommand}></EditableTweet>
        </div>
    )
}

export default Thread;