import { DataContextType } from './myTypes';
import { createContext } from 'react';

const DataContext = createContext<DataContextType>({
    roots: [],
    stack: [],
    content: {},
    relationships: {},
    pushOntoStack: () => { },
    popFromStack: () => { return "" },
    level: 0,
    showLoading: false,
    incrementLevel: () => { },
    decrementLevel: () => { },
    replaceTopOfStack: () => { },
    addRoot: () => { return "" },
    addTweet: () => { },
    addMultipleTweets: () => { },
    replaceStack: () => { },
    constructMessages: () => { return [] },
    forceConstructMessages: () => { return [] },
    _hasChildren: () => { return false },
    setLoading: () => { return false },
});

export default DataContext;

