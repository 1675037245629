import React, { useContext, useEffect, useRef } from "react";
import DataContext from "./DataContext";
import { SystemCommand } from "./myTypes";

import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import SendButton from "./SendButton";
import { toast } from "sonner";

interface Props {
    root: string;
    flipToDirty: (rootId?: string) => void;
    waiting: SystemCommand | null;
}

function Waiting() {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.625 7.5C3.625 8.12132 3.12132 8.625 2.5 8.625C1.87868 8.625 1.375 8.12132 1.375 7.5C1.375 6.87868 1.87868 6.375 2.5 6.375C3.12132 6.375 3.625 6.87868 3.625 7.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM12.5 8.625C13.1213 8.625 13.625 8.12132 13.625 7.5C13.625 6.87868 13.1213 6.375 12.5 6.375C11.8787 6.375 11.375 6.87868 11.375 7.5C11.375 8.12132 11.8787 8.625 12.5 8.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
    )
}

function Button({ waiting }: { waiting: boolean }) {
    return (
        <>
            {waiting ? <Waiting /> : <SendButton />}
        </>
    )
}

function EditableTweet(props: Props) {
    const data = useContext(DataContext);
    const buttonRef = useRef<HTMLDivElement>(null);
    const textBoxRef = useRef<HTMLDivElement>(null);

    const editor = useEditor({
        extensions: [StarterKit],
        content: ``,
        onFocus: (evt) => {
            if (!evt.editor.isFocused) return;
            if (textBoxRef.current?.style.getPropertyValue("height") !== "90%") {
                textBoxRef.current?.style.setProperty("height", "90%");
            }
            buttonRef.current?.scrollIntoView({ behavior: "instant" });
        },
        onUpdate: (evt) => {
            // only update if the editor height has changed
            if (evt.editor.getText().length === 0) return;
            buttonRef.current?.scrollIntoView({ behavior: "instant" });
        },
    })

    useEffect(() => {
        if (props.waiting !== null) {
            editor?.setEditable(false);
        } else {
            editor?.setEditable(true);
        }
    }, [props.waiting, editor])

    const focusEditor = () => {
        if (!editor) return;
        if ((editor.isFocused)) return;

        editor.commands.focus('end');
    }

    const onButtonSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit();
    }

    const onSubmit = () => {
        if (!editor) return;

        const textContent = editor.getText();
        const textContentLength = textContent.trim().length;
        if (textContentLength === 0) return;
        if (textContentLength > 1024) {
            toast.warning("Your message is too long - please keep it under 1024 characters.")
            return;
        }

        const content = editor.getHTML();
        data.addTweet(props.root, content);
        props.flipToDirty();

        // clear editor content
        editor.commands.clearContent();

        // unfocus editor + button to move to the next pane
        editor.commands.blur();
        buttonRef.current?.blur();

    }

    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
            e.stopPropagation();
            e.preventDefault();
            onSubmit();
        }
        if (e.key === "Enter") {

            // buttonRef.current?.scrollIntoView({ behavior: "instant" });
        }
    }


    return (
        <div onClick={focusEditor} onKeyDown={onEnter} className="editableTweet">
            <div className="text-box" ref={textBoxRef}>
                <EditorContent editor={editor} />
            </div>
            <div ref={buttonRef} className="sendBar">
                <button onClick={onButtonSubmit} style={{ width: "90px" }}>
                    <Button waiting={props.waiting !== null} />
                </button>
            </div>
        </div>
    )
}

export default EditableTweet;