function SendButton() {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "var(--button-height)", height: "var(--button-height)", padding: "0", margin: "0" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FFFFFF" viewBox="0 0 256 256"><path d="M180,144H160V112h20a36,36,0,1,0-36-36V96H112V76a36,36,0,1,0-36,36H96v32H76a36,36,0,1,0,36,36V160h32v20a36,36,0,1,0,36-36ZM160,76a20,20,0,1,1,20,20H160ZM56,76a20,20,0,0,1,40,0V96H76A20,20,0,0,1,56,76ZM96,180a20,20,0,1,1-20-20H96Zm16-68h32v32H112Zm68,88a20,20,0,0,1-20-20V160h20a20,20,0,0,1,0,40Z"></path></svg>
            </div>
            <div style={{ fontSize: "var(--button-height)", lineHeight: "var(--button-height)", height: "var(--button-height)" }}>
                Enter
            </div>
        </div>
    )
}

export default SendButton;