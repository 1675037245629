
import { useContext, useEffect, useState } from 'react';

import { getResponse } from './chatStream';
import { Content } from './myTypes';

import DataContext from './DataContext';
import StartThread from './StartThread';
import RootTweet from './RootTweet';
import { toast } from 'sonner';


function MainThread() {
    const [threadForGptResponse, setThreadForGptResponse] = useState<string | null>(null);
    const data = useContext(DataContext);

    const roots = data.roots;
    const rootsContent = roots.map((id) => ({
        id: id,
        content: data.content[id]
    }));

    const flipToDirty = (id: string) => {
        setThreadForGptResponse(id);
    };

    useEffect(() => {
        if (!threadForGptResponse) return;
        const messages: Content[] = [data.content[threadForGptResponse]]

        getResponse(threadForGptResponse, messages, data, () => setThreadForGptResponse(null), "default");
    }, [threadForGptResponse])

    return (
        <div className='main-thread'>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0.5rem 0" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#21B391" viewBox="0 0 256 256"><path d="M112,164a12,12,0,1,1-12-12A12,12,0,0,1,112,164Zm44-12a12,12,0,1,0,12,12A12,12,0,0,0,156,152Zm60,36a52,52,0,0,1-88,37.52A52,52,0,1,1,56.72,149.8,71.58,71.58,0,0,1,63,129C44,91.1,32.53,40.76,45.64,19.08A22,22,0,0,1,65.06,8c14.12,0,26,11.89,36.44,36.36,6.22,14.62,10.85,31.32,14,44.74a71.8,71.8,0,0,1,25,0c3.13-13.42,7.76-30.12,14-44.74C164.9,19.89,176.82,8,190.94,8a22,22,0,0,1,19.42,11.08C223.47,40.76,212,91.1,193,129a71.58,71.58,0,0,1,6.26,20.76A51.77,51.77,0,0,1,216,188ZM155.89,93.63a71.72,71.72,0,0,1,26.88,19.64A218.45,218.45,0,0,0,197.6,67.08c3.49-18.13,3.15-33-.93-39.72A6,6,0,0,0,190.94,24c-6.61,0-14.52,9.7-21.72,26.62C163.29,64.56,158.87,80.74,155.89,93.63ZM73.23,113.27a71.72,71.72,0,0,1,26.88-19.64c-3-12.89-7.4-29.07-13.33-43C79.58,33.7,71.67,24,65.06,24a6,6,0,0,0-5.73,3.36C55.25,34.1,54.91,49,58.4,67.08A218.45,218.45,0,0,0,73.23,113.27ZM200,188a35.87,35.87,0,0,0-13.34-28,8,8,0,0,1-2.92-5.45,56,56,0,0,0-111.48,0A8,8,0,0,1,69.34,160a36,36,0,1,0,47.28,54.21l-9.74-8.09a8,8,0,1,1,10.24-12.3L128,202.9l10.88-9.05a8,8,0,0,1,10.24,12.3l-9.74,8.09A36,36,0,0,0,200,188Z"></path></svg>

                <div style={{ fontSize: "1.25rem", color: "#555555" }}>lapin</div>

                <svg className='info' style={{}} onClick={() => toast("A Twitter-like interface for LLM chat. Created by <a href=\"https://x.com/ajeon66\">@ajeon66.</a>")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#555555" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path></svg></div>
            <StartThread flipToDirty={flipToDirty} waiting={threadForGptResponse} />
            {rootsContent.map((c, index) => (
                <div onClick={() => data.replaceStack([c.id])}>
                    <RootTweet key={index} id={c.id} content={c.content} hasChildren={data._hasChildren(c.id)} />
                </div>
            ))}
        </div>
    )
}

export default MainThread;